html,
body,
#root,
#content {
    height: 100%;
}

body {
    background-color: var(--primary);
}

.card.tiny {
    max-width: 560px;
    width: 100%;
    margin: 8px;
    border-radius: 0.5rem;
}

.button-full {
  max-width: 400px;
  width: 100%;
  text-align: center;
  display: block;
  font-size: .875rem;
  position: relative;
  padding: 0.75rem 0;
}

.booksy-button {
  position: absolute;
  right: 0.675rem;
  top: 50%;
  transform: translateY(-51%);
}
