$theme-colors: (
  primary: #04677e,
  secondary: #86c0c8,
  dark: #2a2c32,
  light: #eeeeee,
  table-title: #4f525c,
);

$status-colors: (
  yellow: #ffd600,
  green: #1bbe00,
  gray: #d9d9d9,
);

@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&family=Roboto:wght@300;400;700&display=swap");

.h6 {
  font-size: 0.875rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

.raleway {
  font-family: "Raleway", sans-serif;
}

label {
  font-size: 0.9rem;
}

.table th,
.table td {
  font-size: 0.875rem;
  padding: 0.5rem;
}

.table thead th {
  color: map-get($theme-colors, "table-title");
}

.table thead td {
  color: map-get($theme-colors, "dark");
}

.table thead th {
  border: none;
}

.table th,
.table td {
  border: none;
}

.status {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: 0 auto;
  display: block;
}

.text-weight-300 {
  font-weight: 300;
}

.mx-gap {
  margin: 0 15px;
}

@each $name, $val in $status-colors {
  .status-#{$name} {
    background: $val;
  }
}

select.form-control::-ms-expand {
  display: none;
}

select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22chevron-down%22%20class%3D%22svg-inline--fa%20fa-chevron-down%20fa-w-14%20fa-12%20%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M207.029%20381.476L12.686%20187.132c-9.373-9.373-9.373-24.569%200-33.941l22.667-22.667c9.357-9.357%2024.522-9.375%2033.901-.04L224%20284.505l154.745-154.021c9.379-9.335%2024.544-9.317%2033.901.04l22.667%2022.667c9.373%209.373%209.373%2024.569%200%2033.941L240.971%20381.476c-9.373%209.372-24.569%209.372-33.942%200z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.75rem auto, 100%;
}
