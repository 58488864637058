#wrapper {
  display: flex;
  width: 100%;
  @media (max-width: 575.98px) {
    display: block;
  }
}

#main-nav {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  .avatar-img {
    max-width: 80px;
  }
  margin-left: -300px;
  width: 300px;
  min-height: 100vh;
  &.active {
    margin-left: 0;
  }
  .nav-link {
    color: var(--light);
    font-family: "Roboto", sans-serif;
    padding: 1rem;
    font-weight: 400;
    &.active {
      color: var(--secondary);
    }
  }
  transition: all 0.5s ease-in-out;
}

#content {
  flex: 1;
  min-height: 100vh;
  overflow-x: auto;
}

.menu-button {
  display: block;
}
.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  top: 0;
  left: 0;
}
/* display .overlay when it has the .active class */
.overlay.active {
  display: block;
  opacity: 1;
}

@media (min-width: 768px) {
  #main-nav {
    margin-left: 0;
    position: relative;
  }
  .menu-button {
    display: none;
  }
}

.hide-number-arrows {
  -moz-appearance: textfield;
}
.hide-number-arrows::-webkit-inner-spin-button {
  display: none;
}
.hide-number-arrows::-webkit-outer-spin-button,
.hide-number-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.required .form-label:after {
  content: " *";
  color: red;
}

.day_picker {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  position: absolute;
  top: 48px;
  right: 0;
  z-index: 20;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.day_picker.opened {
  opacity: 1;
  pointer-events: all;
}

.day_picker:after,
.day_picker:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.day_picker:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 10px;
  left: 90%;
  margin-left: -10px;
}

.day_picker:before {
  border-color: rgba(113, 158, 206, 0);
  border-width: 11px;
  left: 90%;
  margin-left: -11px;
}

.Range
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Range .DayPicker-Day {
  border-radius: 0 !important;
}
